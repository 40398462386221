import React, { useState } from "react";
import { useFormik } from "formik";
import { FcGoogle } from "react-icons/fc";
import { useNavigate, Navigate } from "react-router-dom";

import {
  TextAndField,
  Text,
  Field,
  FillButton,
  OutlinedButton,
} from "../Reusable/Form/Form";
import OboardingLogo from "../Reusable/OboardingLogo";
import { useDispatch } from "react-redux";
import { authLoginThunk } from "../../store/login";
import { LoginType } from "../types/types";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { CircularProgress } from "@mui/material";
import Error from "../Reusable/Errors/Error";
import { Storage } from "../../network/StorageClass";
const storage = new Storage();

interface Props {
  validateToken: boolean;
}
const Login: React.FC<Props> = ({ validateToken }) => {
  const dispatch: ThunkDispatch<"auth", void, AnyAction> = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [ErrorMsg, setErrorMsg] = useState<string>("");

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values: LoginType) => {
      // Handle form submission here
      setIsLoading(true);
      dispatch(authLoginThunk(values)).then((val) => {
        setIsLoading(false);
        if (val.payload?.response?.status !== undefined) {
          setErrorMsg(val.payload?.response?.data?.message);
          setIsError(true);
        } else if (val?.payload?.code === "ERR_NETWORK") {
          setErrorMsg("Server not recheable");
          setIsError(true);
        } else {
          storage.AuthStorage(val?.payload?.accessToken, val?.payload?.user);
          navigate(0);
        }
      });
    },
  });
  if (validateToken) {
    return <Navigate to="/sentences" />;
  }

  return (
    <div className="flex">
      <div className="w-[50%] flex flex-col justify-center items-center">
        <div className="w-[60%] flex flex-col gap-8">
          <p className="text-[26px] mb-1">
            Login to{" "}
            <span
              className="text-primary 
                font-semibold">
              Clive AI
            </span>{" "}
          </p>

          <div className="flex flex-col gap-5">
            <TextAndField>
              <Text text="Email Address" color="#484848" size="14px" />
              <Field
                text="Enter Email Address"
                value={formik.values.email}
                name="email"
                formik={formik}
                size="medium"
                variant="standard"
              />
            </TextAndField>

            <TextAndField>
              <Text text="Password" color="#484848" size="14px" />
              <Field
                text="Enter Password"
                type="password"
                value={formik.values.password}
                name="password"
                formik={formik}
                size="medium"
                variant="standard"
              />
            </TextAndField>
          </div>

          {isError && (
            <Error message={ErrorMsg} handleClick={() => setIsError(false)} />
          )}

          <div className="flex flex-col gap-3">
            {!isLoading ? (
              <FillButton
                text="Login"
                color="#292D32"
                height="45px"
                callBack={formik.handleSubmit}
              />
            ) : (
              <div className="w-[100%] flex justify-center">
                <CircularProgress />
              </div>
            )}
            <OutlinedButton
              text="Sign in with Google"
              Icon={<FcGoogle className="text-[20px]" />}
              height="45px"
              callBack={() => {}}
            />
          </div>

          <p className="text-[14px] text-center">
            Don’t have an account?{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => navigate("/signup")}>
              Signup
            </span>{" "}
          </p>
        </div>
      </div>

      <OboardingLogo />
    </div>
  );
};

export default Login;
