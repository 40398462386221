import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { predictIntendThunk } from "../../../../store/intend";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface ReceivedData {
  msg: string;
  ans: {
    time: string;
    error?: string;
    sentence: string;
    confidence: number;
    intendId?: string;
  };
}
const usePridiction = () => {
  const dispatch: ThunkDispatch<"auth", void, AnyAction> = useDispatch();
  const isLoading = useSelector((state: any) => state.intend).loading;

  const [text, setText] = useState<string>("");
  const [chatData, setChatData] = useState<ReceivedData[]>([]);

  const predict = useCallback(async () => {
    if (text.trim().length === 0) {
      toast.error("A prompt is required");
      return;
    }

    const userMsg = {
      msg: text,
      ans: {
        time: "delete",
        sentence: "",
        confidence: 0,
        error: "",
        intendId: "",
      },
    };

    setChatData((prevChatData) => [...prevChatData, userMsg]);

    dispatch(predictIntendThunk({ sentence: text }) as any).then((val: any) => {
      if (val.payload.status === 200) {
        console.log(val.payload.status);
        setChatData((prevChatData) => [
          ...prevChatData.filter((x) => x.ans.time !== "delete"),
          {
            msg: text,
            ans: {
              time: val.payload?.time,
              sentence: val.payload?.sentence,
              confidence: val.payload?.confidence,
              error: val.payload?.error,
              intendId: val.payload?.intent_id,
            },
          },
        ]);
      }
      setText("");
    });
  }, [text, setChatData]);

  return { setText, chatData, predict, isLoading, text };
};

export default usePridiction;
