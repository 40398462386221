import { useFormik } from "formik";
import { FcGoogle } from "react-icons/fc";
import { useNavigate, Navigate } from "react-router-dom";

import {
  TextAndField,
  Text,
  Field,
  FillButton,
  OutlinedButton,
  FieldSelect,
} from "../Reusable/Form/Form";
import OboardingLogo from "../Reusable/OboardingLogo";

interface Props {
  validateToken: boolean;
}

const SignUp: React.FC<Props> = ({ validateToken }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "",
      phone: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      // Handle form submission here
    },
  });

  if (validateToken) {
    return <Navigate to="/sentences" />;
  }

  return (
    <div className="flex">
      <div className="w-[50%] flex flex-col justify-center items-center">
        <div className="w-[60%] flex flex-col gap-5">
          <div className="text-[26px] flex flex-col">
            <p>Get Started with</p>
            <p className="text-primary font-semibold">Clive AI</p>
          </div>

          <div className="flex flex-col gap-6">
            <TextAndField space={0.5}>
              <Text text="First Name" color="#484848" size="12px" />
              <Field
                text="Enter First Name"
                value={formik.values.firstName}
                name="firstName"
                formik={formik}
                size="small"
                variant="standard"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text="Last Name" color="#484848" size="12px" />
              <Field
                text="Enter Last Name"
                value={formik.values.lastName}
                name="lastName"
                formik={formik}
                size="small"
                variant="standard"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text="Country" color="#484848" size="12px" />
              <FieldSelect
                list={[{ id: "1", name: "United State" }]}
                placeholder="Choose country"
                value={formik.values.country}
                name="country"
                formik={formik}
                size="small"
                width="100%"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text="Phone Number" color="#484848" size="12px" />
              <Field
                text="Enter Phone Number"
                value={formik.values.phone}
                name="phone"
                formik={formik}
                size="small"
                variant="standard"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text="Email Address" color="#484848" size="12px" />
              <Field
                text="Enter Email Address"
                value={formik.values.email}
                name="email"
                formik={formik}
                size="small"
                variant="standard"
              />
            </TextAndField>

            <TextAndField space={0.5}>
              <Text text="Password" color="#484848" size="12px" />
              <Field
                text="Password"
                type="password"
                value={formik.values.password}
                name="password"
                formik={formik}
                size="small"
                variant="standard"
              />
            </TextAndField>
          </div>

          <div className="flex flex-col gap-3">
            <FillButton
              text="Continue"
              height="45px"
              color="#292D32"
              callBack={() => {}}
            />
            <OutlinedButton
              text="Sign in with Google"
              Icon={<FcGoogle className="text-[20px]" />}
              height="45px"
              callBack={() => {}}
            />
          </div>

          <p className="text-[12px] text-center">
            Already have an account?
            <span
              className="text-primary cursor-pointer"
              onClick={() => navigate("/")}>
              {" "}
              Login
            </span>{" "}
          </p>
        </div>
      </div>

      <OboardingLogo />
    </div>
  );
};

export default SignUp;
