import { forwardRef, ReactNode } from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { CheckIcon, DividerHorizontalIcon } from "@radix-ui/react-icons";

export const DropdownMenu = DropdownMenuPrimitive.Root;

export const DropdownMenuTrigger = forwardRef<
  HTMLDivElement,
  { children: ReactNode; className?: string }
>(({ children, className, ...props }) => {
  return (
    <DropdownMenuPrimitive.Trigger
      {...props}
      className={`focus-visible:outline-none  ${className}`}
    >
      {children}
    </DropdownMenuPrimitive.Trigger>
  );
});

export const DropdownMenuContent = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        align="start"
        {...props}
        ref={forwardedRef}
        className="w-24 rounded-sm bg-[white] shadow-md "
      >
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  );
});

export const DropdownMenuItem = forwardRef<
  HTMLDivElement,
  { children: ReactNode; className: string }
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Item
      ref={forwardedRef}
      className={`px-2 py-1 hover:bg-hover-gray text-[14px] cursor-pointer focus-visible:outline-none  ${className}`}
      {...props}
    >
      {children}
    </DropdownMenuPrimitive.Item>
  );
});

export const DropdownMenuLabel = DropdownMenuPrimitive.Label;
// export const DropdownMenuItem = DropdownMenuPrimitive.Item;
export const DropdownMenuGroup = DropdownMenuPrimitive.Group;

export const DropdownMenuCheckboxItem = forwardRef<
  HTMLDivElement,
  { children: ReactNode; checked?: boolean | "indeterminate" }
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.CheckboxItem {...props} ref={forwardedRef}>
      {children}
      <DropdownMenuPrimitive.ItemIndicator>
        {props.checked === "indeterminate" && <DividerHorizontalIcon />}
        {props.checked === true && <CheckIcon />}
      </DropdownMenuPrimitive.ItemIndicator>
    </DropdownMenuPrimitive.CheckboxItem>
  );
});

export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export const DropdownMenuRadioItem = forwardRef<
  HTMLDivElement,
  { children: ReactNode; value: string }
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.RadioItem {...props} ref={forwardedRef}>
      {children}
      <DropdownMenuPrimitive.ItemIndicator>
        <CheckIcon />
      </DropdownMenuPrimitive.ItemIndicator>
    </DropdownMenuPrimitive.RadioItem>
  );
});

export const DropdownMenuSeparator = DropdownMenuPrimitive.Separator;
