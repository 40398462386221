import { ChangeEvent, KeyboardEventHandler } from "react"
import {FaTimes} from 'react-icons/fa'
import { CustomIconButton } from "./Form"

interface CustomInputNoFormikProps {
    handleChange:(e: ChangeEvent<HTMLInputElement>)=>void, 
    value:string,
    cancelField?: ()=>void,
    handleOnKeyUp?:KeyboardEventHandler<HTMLInputElement>,
    withCancel?: boolean,
    disable?: boolean
}


export const CustomInputNoFormik = ({
  handleChange, 
  value, 
  cancelField, 
  handleOnKeyUp,
  withCancel = true,
  disable = false
}: CustomInputNoFormikProps) => {
  return (
    <div className='flex gap-2'>
        <input disabled={disable} onChange={handleChange} onKeyDown={handleOnKeyUp} value={value} className='border-1 border-border-color text-gray-5A px-2 py-1 rounded-[3px] focus:outline-none focus:border-primary focus:border-2' type="text" />
        {withCancel && <CustomIconButton Icon={<FaTimes className='text-[14px]'/>} callBack={cancelField}/>}
    </div>
    
  )
}