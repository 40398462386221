import React,{ReactNode, ChangeEvent} from 'react';
import { Typography, TextField, Button, Stack, IconButton, OutlinedInput, CircularProgress} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { BsPerson } from 'react-icons/bs';


interface TextProps {
  text: string;
  size?: string;
  weight?: string;
  color?: string
}

export const Text: React.FC<TextProps> = ({ text, size = '12px', weight = '500', color='black' }) => {
  return (
    <Typography sx={{ fontSize: size, fontWeight: weight, color: color}}>
      {text}
    </Typography>
  );
};

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
  },
  borderNone: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
});

interface FieldProps {
  text: string;
  handleChange?: () => void;
  disable?: boolean;
  type?: string;
  sx?: any;
  value?: any;
  formik?: any;
  name?: string;
  multiline?: boolean;
  maxRows?: number;
  width?: string;
  size?: 'small' | 'medium';
  containerWidth?: string;
  className?: string;
  onKeyDown?: () => void;
  variant?: 'outlined'|'standard'|'filled',
  fontStyle?: string
}

export const Field: React.FC<FieldProps> = ({
  text,
  handleChange = () => {},
  disable = false,
  type,
  sx,
  value,
  formik,
  name,
  multiline,
  maxRows,
  width,
  size = 'small',
  containerWidth = '100%',
  className,
  onKeyDown,
  variant = 'outlined',
  fontStyle = '10px'
}) => {
  const classes = useStyles();
  return (
    <>
      <TextField
        id="outlined-basic"
        size={size}
        name={name === undefined ? '' : name}
        disabled={disable}
        type={type === undefined ? 'text' : type}
        variant={variant}
        placeholder={text}
        inputProps={{
          onKeyDown: onKeyDown,
          style: {
            fontSize: fontStyle,
            width: width !== undefined ? width : '300px',
            color: formik?.errors[name!] && formik?.touched[name!] ? 'red' : '',
            ...sx,
          },
        }}
        value={value === undefined ? '' : value}
        onChange={formik === undefined ? handleChange : formik.handleChange}
        onBlur={formik === undefined ? () => {} : formik.onBlur}
        className={
          formik?.errors[name!] && formik?.touched[name!]
            ? classes.root
            : className
        }
        multiline={multiline !== undefined ? multiline : false}
        minRows={maxRows !== undefined ? maxRows : 1}
        maxRows={maxRows !== undefined ? maxRows : 1}
        sx={{ width: containerWidth }}
      />
      {formik?.errors[name!] && formik?.touched[name!] && (
        <Typography sx={{ fontSize: fontStyle,  color: 'red', p: 0 }}>
          {formik.errors[name!]}
        </Typography>
      )}
    </>
  );
};

interface FieldSelectProps {
  list: { id: string; name: string }[];
  value: string;
  formik?: any;
  name?: string;
  callback?: () => void;
  size?: 'small'|'medium';
  height?: string;
  width?: string;
  placeholder?: string;
  otherProps?: any;
}

export const FieldSelect: React.FC<FieldSelectProps> = ({
  list,
  value,
  formik,
  name,
  callback,
  size = 'small',
  height = '35px',
  width = '330px',
  placeholder = 'Choose',
  otherProps,
}) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      {callback !== undefined && (
        <Select
          IconComponent={ExpandMoreIcon}
          size={size}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value === undefined ? '' : value}
          name={name === undefined ? '' : name}
          onChange={callback}
          onBlur={formik === undefined ? () => {} : formik.onBlur}
          sx={{ width: { width }, height: { height },  ...otherProps, }}
          className={
            formik.errors[name!] && formik.touched[name!] ? classes.root : ''
          }
        >
          <MenuItem sx={{  ...otherProps }} value={'none'} disabled>
            {placeholder}
          </MenuItem>
          {list !== undefined &&
            list.map((val) => (
              <MenuItem sx={{  ...otherProps }} value={val.id}>
                {val.name}
              </MenuItem>
            ))}
        </Select>
      )}
      {callback === undefined && (
        <Select
          IconComponent={ExpandMoreIcon}
          size={size}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value === undefined ? '' : value}
          name={name === undefined ? '' : name}
          onChange={formik === undefined ? () => {} : formik.handleChange}
          onBlur={formik === undefined ? () => {} : formik.onBlur}
          sx={{ fontSize: '11px', width: { width }, height: { height },  ...otherProps }}
          className={
            formik.errors[name!] && formik.touched[name!] ? classes.root : ''
          }
        >
          <MenuItem sx={{  ...otherProps, fontSize: '11px',}} value={'none'} disabled>
            {placeholder}
          </MenuItem>
          {list !== undefined &&
            list.map((val) => (
              <MenuItem sx={{  ...otherProps, fontSize: '11px', }} value={val.id}>
                {val.name}
              </MenuItem>
            ))}
        </Select>
      )}
      {formik.errors[name!] && formik.touched[name!] && (
        <Typography sx={{ fontSize: '10px',  color: 'red', p: 0 }}>
          {formik.errors[name!]}
        </Typography>
      )}
    </FormControl>
  );
};

interface FieldSelectWithOutFormikProps {
  list: { id: string; name: string }[];
  value: string;
  disable?: boolean;
  fontSize?: string;
  callback: (event: SelectChangeEvent) => void;
  size?: 'small'|'medium';
  height?: string;
  width?: string;
  placeholder?: string;
}

export const FieldSelectWithOutFormik: React.FC<FieldSelectWithOutFormikProps> = ({
  list,
  value,
  disable = false,
  fontSize = '18px',
  callback = () => {},
  size = 'small',
  height = '35px',
  width = '330px',
  placeholder = 'Choose',
}) => {
  return (
    <FormControl fullWidth>
      {callback !== undefined && (
        <Select
          IconComponent={ExpandMoreIcon}
          disabled={disable}
          size={size}
          value={value}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{ width, height: height, fontSize }}
          onChange={(e)=>callback(e)}
        >
          <MenuItem sx={{ fontSize: '12px' }} value={'none'} disabled>
            {placeholder}
          </MenuItem>

          {list !== undefined &&
            list.map((val) => (
              <MenuItem sx={{ fontSize }} value={val.id}>
                {val.name}
              </MenuItem>
            ))}
        </Select>
      )}
    </FormControl>
  );
};

interface FillButtonProps {
  text: string;
  callBack: () => void;
  disable?: boolean;
  height?: string;
  width?: string;
  textSize?: string;
  color?: string,
  elevation?: boolean
}

export const FillButton: React.FC<FillButtonProps> = ({
  text,
  callBack,
  disable,
  height,
  width,
  textSize = '14px',
  color= 'black',
  elevation= false
}) => {
  return (
    <Button
        disableElevation={!elevation}
      sx={{
        textTransform: 'none',
        backgroundColor: '#DEFFF1',
        color: color,
        height: height !== undefined ? height : '36px',
        width: width !== undefined ? width : '93',
        fontFamily: 'TT commons',
        
        ':hover': { backgroundColor: '#c7e5d8' },
      }}
      variant="contained"
      disabled={disable}
      onClick={callBack}
    >
      <Typography sx={{  fontSize: textSize }}>{text}</Typography>
    </Button>
  );
};

interface TextAndFieldProps {
  space?: number;
  width?: string;
  children: ReactNode;
}

export const TextAndField: React.FC<TextAndFieldProps> = ({
  children,
  space = 1,
  width = '100%',
}) => {
    if (!children) {
      return null;
    }
  
    return (
      <Stack direction="column" gap={space} sx={{ width }}>
        {Array.isArray(children) ? children : [children]}
      </Stack>
  );
};

interface OutlinedButtonProps {
  text: string;
  callBack: () => void;
  disable?: boolean;
  height?: string;
  width?: string;
  textSize?: string;
  bg?: string;
  themeColor?: string;
  Icon?: ReactNode;
  variant?: 'outlined'|'text'|'contained',
  style?: any
}

export const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  text,
  callBack,
  disable,
  height,
  width,
  textSize = '14px',
  bg = '',
  themeColor = '#242424',
  Icon,
  variant = 'outlined',
  style

}) => {
  return (
    <Button
      sx={{
        textTransform: 'none',
        color: themeColor,
        borderColor: themeColor,
        height: height !== undefined ? height : '36px',
        width: width !== undefined ? width : '93',
        fontFamily: 'TT commons, sans-serif',
        backgroundColor: bg,
        ':hover': { borderColor: 'rgba(0,0,0,0.2)' },
      }}
      variant={variant}
      disabled={disable}
      onClick={callBack}
    >
        <div className='flex gap-2 items-center'>
            {Icon}
            <Typography sx={{  fontSize: textSize, ...style }}>{text}</Typography>
        </div>
    </Button>
  );
};

interface CustomIconButtonProps {
  Icon: ReactNode;
  callBack?: () => void;
  loading?: boolean;
  disable?: boolean
}

export const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  Icon,
  callBack = () => {},
  loading = false,
  disable = false
}) => {
  return (
    <IconButton disabled={loading || disable} onClick={callBack}>
      {!loading && Icon} 
      {loading && <CircularProgress size={'20px'} color='secondary'/>}
    </IconButton>
  );
};

interface SimpleSelectProps {
  height?: string;
  value?: string;
  marginTop?: string;
  textColor?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  textSize?: string;
  list?: { id: string; name: string }[];
  width?: string;
  label?: string;
  labelColor?: string;
  bgColor?: string;
}

export const SimpleSelect: React.FC<SimpleSelectProps> = ({
  height,
  value,
  marginTop,
  textColor,
//   handleChange,
  textSize,
  list,
  width,
  label,
  labelColor,
  bgColor,
}) => {
    
  return (
    <FormControl
      sx={{
        width,
        marginTop,
      }}
    >
      {label && (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '500',
            color: labelColor ? labelColor : 'rgba(36,36,36,1)',
            mb: '5px',
          }}
        >
          {label}
        </Typography>
      )}
      <Select
        value={value}
        // onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          height: height ? height : '35px',
          fontSize: textSize ? textSize : '12px',
          color: textColor ? textColor : 'rgba(36,36,36,1)',
          backgroundColor: bgColor,
        }}
      >
        {list &&
          list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

interface IconTextFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  icon?: React.ComponentType<any>;
  iconStyle?: string;
  inputProps?: any;
  error?: boolean;
  helperText?: string;
}

export const IconTextField: React.FC<IconTextFieldProps> = ({
  value,
  onChange,
  label,
  icon: Icon,
  iconStyle,
  inputProps,
  error,
//   helperText,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {Icon && <Icon className={`text-[18px] ${iconStyle}`} />}
      <OutlinedInput
        value={value}
        onChange={onChange}
        placeholder={label}
        endAdornment={null}
        inputProps={inputProps}
        error={error}
      />
    </Stack>
  );
};

export const UserIcon: React.FC = () => {
  return <BsPerson className="text-[18px]" />;
};
