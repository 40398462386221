import React, {useState} from "react";
import { toast } from "react-toastify";
import { CreateSentenceTypes } from "../../../types/types";
import { useDispatch } from "react-redux"
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { createSentenceThunk, getSentenceThunk } from "../../../../store/sentence";

const useCreateSentence = (setOpenIntend: React.SetStateAction<any>, setOpenSentence: React.SetStateAction<any>, sentence: string, intend: string) => {
    const dispatch: ThunkDispatch<"auth", void, AnyAction> = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSucessCreating, setIsSuccessCreating] = useState(false)

    const handleSaveSentence = (enterButton = false) => {
    setIsLoading(true);

    if (sentence === "" || intend === "") {
      toast.error("Make sure sentence and intent are filled");
      setIsLoading(false);
      return;
    }

    const data: CreateSentenceTypes = {
      sentence,
      sentence_ner: sentence,
      intent_id: parseInt(intend),
    };
    dispatch(createSentenceThunk({ data })).then((val: any) => {
      if([201, "201"].includes(val.payload.status)){

        if (enterButton) {
          setIsLoading(false);
          toast.success(val.payload?.message);
          setIsSuccessCreating(true)
          setOpenSentence(false)
          // setOpenIntend(true)
        } else {
          setIsLoading(false);
          toast.success(val.payload?.message);
          setOpenSentence(false);
          setOpenIntend(false);
        }
        dispatch(getSentenceThunk())

      }
      
    }).catch(err => setIsLoading(false))
  };

  return {isLoading, isSucessCreating, handleSaveSentence}
}

export default useCreateSentence