import MainImage from '../../assets/main-image.gif'

const OboardingLogo = () => {
  return (
    <div className='flex flex-col items-center w-[50%] h-[100vh] bg-light-gray'>
    <img src={MainImage} alt="" className='w-[423px] h-[449px]'/>

    <div className='w-[70%] m-auto text-center'>
        <p className='text-[26px] font-semibold'> <span className='text-primary'>Clive</span>, your AI assistant</p>
        <p className='text-[16px] text-gray-5A'>Clive is designed to help you easily manage your daily tasks and routines so you can focus on what matters most.</p>
    </div>

</div>
  )
}

export default OboardingLogo