import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

import { SingleIntendType } from "../../../types/types";

type ChartType = {
  chartData: SingleIntendType[];
};

const Chart = ({ chartData }: ChartType) => {
  const data = chartData.filter((dt) => dt?.active === 1);
  return (
    <div className="h-[60vh] w-[98%] mx-auto mt-5 pr-3 py-3 rounded-lg shadow-md ">
      <ResponsiveContainer className="" width={"100%"} height={"100%"}>
        <BarChart width={730} height={250} data={data}>
          <XAxis dataKey="action" tick={{ fontSize: 11 }} />
          <YAxis tick={{ fontSize: 11 }} />
          <Tooltip />
          <Legend />
          <Bar dataKey="sentence_count" fill="#00a35e" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
 
export default Chart;
