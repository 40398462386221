import { useState, useEffect, useCallback } from "react";
import Layout from "../../Reusable/Layout";
import { FillButton } from "../../Reusable/Form/Form";
import { colors } from "../../Reusable/Details/Colors";
import { OutlinedButton } from "../../Reusable/Form/Form";
import { BsPlus } from "react-icons/bs";
import ModalTemplate from "../../Reusable/Modal";
import { useFormik } from "formik";
import { Field, Text, TextAndField } from "../../Reusable/Form/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  getIntendThunk,
  createIntendThunk,
  editIntendThunk,
  editIntendStatusThunk,
} from "../../../store/intend";
import { CreateIntendType, SingleIntendType } from "../../types/types";
import { motion, AnimatePresence } from "framer-motion";
import { CustomInputNoFormik } from "../../Reusable/Form/input";
import { CustomIconButton } from "../../Reusable/Form/Form";
import { FiSave } from "react-icons/fi";
import { toast } from "react-toastify";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { CircularProgress } from "@mui/material";
import TrainModelButton from "../../Reusable/TrainModelButton";
import { useNavigate } from "react-router-dom";
import CustomRow from "../../Reusable/Details/CustomRow";
import PopDetail from "../../Reusable/Details/PopDetail";
import { FieldSelectWithOutFormik } from "../../Reusable/Form/Form";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../Reusable/Dropdown";
import Chart from "./component/Chart";

import styles from "./intend.module.css";
type SortingTypes = "none" | "intent_id" | "intent" | "action" | "commodity";

const Intend = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<"auth", void, AnyAction> = useDispatch();
  const intendStore = useSelector((state: any) => state.intend);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<SingleIntendType[]>([]);
  const [statusText, setStatusText] = useState("");
  const [statusActive, setStatusActive] = useState<boolean>(false);
  const [dataToMap, setDataToMap] = useState<SingleIntendType[]>(data);
  const [currentStatus, setCurrentStatus] = useState(-1);
  const [viewIntendData, setViewIntendData] = useState<SingleIntendType | null>(
    null
  );
  const [editableRowIndex, setEditableRowIndex] = useState<number | null>(null);
  const [editedIntend, setEditedIntend] = useState<string | null>(null);
  const [editedIntendId, setEditedIntendId] = useState<string | null>(null);
  const [editedAction, setEditedAction] = useState<string | null>(null);
  const [editedCommodity, setEditedCommodity] = useState<string | null>(null);
  const statusList = [
    {
      id: 0,
      label: "All",
      value: -1,
    },
    {
      id: 1,
      label: "Active",
      value: 0,
    },
    {
      id: 2,
      label: "Inactive",
      value: 1,
    },
  ];

  useEffect(() => {
    setDataToMap(data);
  }, [data]);


  const getIntends = useCallback(() => {
    setIsLoading(true);
    dispatch(getIntendThunk() as any)
      .then((val: { payload: any }) => {
        if (val.payload === undefined) navigate("/");
        setData(val?.payload?.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [dispatch, navigate]);

  const reLoadIntends = () => {
    dispatch(getIntendThunk() as any).then((val: { payload: any }) => {
      if (val.payload === undefined) navigate("/");

      setData(val?.payload?.data);
    });
  };

  const handleSort = (criteria: SortingTypes) => {
    const sortedAray = Array.from(data);
    if (criteria !== "none") {
      if (criteria === "intent_id") {
        sortedAray.sort((a, b) => {
          // Sorting logic here
          const nameA = a[criteria];
          const nameB = b[criteria];

          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        sortedAray.sort((a, b) => {
          // Sorting logic here
          const nameA = a[criteria].toUpperCase();
          const nameB = b[criteria].toUpperCase();

          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      setData(sortedAray);
    } else {
      getIntends();
    }
  };

  useEffect(() => {
    getIntends();
  }, [getIntends]);

  const [openPopper, setOpenPopper] = useState<EventTarget | null>(null);
  const [indexxx, setIndex] = useState<number | null>(null);
  const [popperHovered, setPopperHovered] = useState(false);
  const [sortingCriteria, setSortingCriteria] = useState<SortingTypes>("none");

  const handleEdit = (dataa: SingleIntendType) => {
    setOpenPopper(null);
    if (
      editedIntend === null &&
      editedIntendId === null &&
      editedAction === null &&
      editedCommodity === null
    ) {
      setEditableRowIndex(null);
    } else if (
      editedIntend === dataa.intent &&
      editedIntendId === dataa.intent_id &&
      editedAction === dataa.action &&
      editedCommodity === dataa.commodity
    ) {
      setEditableRowIndex(null);
    } else {
      const datas: CreateIntendType = {
        intent_id: editedIntendId ?? dataa.intent_id,
        intent: editedIntend ?? dataa.intent,
        action: editedAction ?? dataa.action,
        commodity: editedCommodity ?? dataa.commodity,
        amount: "",
        origination_entity: {
          data: "*general.image",
          name: "",
          is_default: true,
        },
        destination_entity: "",
        entity_list: ["*general.image"],
        insights: "Thems",
      };

      dispatch(editIntendThunk({ datas, id: dataa._id })).then((val) => {
        toast.success(val.payload.message);
        reLoadIntends();
        setEditableRowIndex(null);
      });
    }
  };

  const handleEditStatus = (dataa: SingleIntendType) => {
    dispatch(
      editIntendStatusThunk({ intentId: dataa.intent_id, status: dataa.active })
    ).then((val: any) => {
      setEditedIntendId(null);
      if (val.payload.status === 200) {
        toast.success("Status updated");
        reLoadIntends();
      } else {
        toast.error(val.payload.data.message);
      }
    });
  };

  function generateRandomNumber() {
    let number = "";

    for (let i = 0; i < 9; i++) {
      const digit = Math.floor(Math.random() * 9) + 1;
      number += digit;
    }

    return number;
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOPenModal = () => {
    setOpenModal(true);
    formik.setFieldValue("intent_id", generateRandomNumber());
  };

  const formik = useFormik({
    initialValues: {
      intent: "",
      intent_id: "",
      action: "",
      commodity: "",

      amount: "",
      origination_entity: {
        data: "*",
        name: "",
        is_default: true,
      },
      destination_entity: "",
      entity_list: ["*"],
      insights: "Thems",
    },
    onSubmit: (values, action) => {
      dispatch(createIntendThunk({ data: values })).then((val) => {
        action.resetForm();
        handleCloseModal();
        toast.success(val.payload.message);
        dispatch(getIntendThunk() as any).then((vall: { payload: any }) => {
          setData([
            {
              _id: "string",
              intent_id: "number",
              intent: "string",
              action: "string",
              commodity: "string",
              destination_entity: "string",
              entity_list: [],
              insights: "string",
              origination_entity: {},
              amount: "string",
            },
            ...vall.payload.data,
          ]);
        });
      });
    },
  });

  const handleOpenPopper = (
    event: React.MouseEvent,
    index: number,
    val: SingleIntendType
  ) => {
    setOpenPopper(event.target);
    setIndex(index);
    setViewIntendData(val);
  };

  const handlePopperMouseEnter = () => {
    setPopperHovered(true);
  };

  const handlePopperMouseLeave = () => {
    setPopperHovered(false);
    setIndex(null);
  };

  const mainAnim = {
    exit: {
      opacity: 0,
      transition: { ease: "easeOut", duration: 0.3 },
    },
  };
  console.log(data);
  return (
    <Layout page="intent" title="Intent List" isLoading={isLoading}>
      <ModalTemplate open={openModal} handleClose={handleCloseModal}>
        <div className="w-[30vw] min-h-[70vh] overflowY-auto bg-[white] py-5 px-8 m-auto rounded-md mt-[5%] flex flex-col gap-4 items-center">
          <p className="text-[18px] mb-3">Create New Intent</p>

          <TextAndField space={0.2}>
            <Text text="Intent Id" />
            <Field
              disable
              text="Enter an intent Id"
              formik={formik}
              name="intent_id"
              fontStyle="14px"
              value={formik.values.intent_id}
            />
          </TextAndField>

          <TextAndField space={0.2}>
            <Text text="Intent" />
            <Field
              text="Enter an intent"
              formik={formik}
              name="intent"
              fontStyle="14px"
              value={formik.values.intent}
            />
          </TextAndField>

          <TextAndField space={0.2}>
            <Text text="Action" />
            <Field
              text="Enter action"
              formik={formik}
              name="action"
              fontStyle="14px"
              value={formik.values.action}
            />
          </TextAndField>

          <TextAndField space={0.2}>
            <Text text="Commodity" />
            <Field
              text="Enter Commodity"
              formik={formik}
              name="commodity"
              fontStyle="14px"
              value={formik.values.commodity}
            />
          </TextAndField>

          <div className="w-[100%] mt-12 flex justify-between">
            <OutlinedButton
              text="Cancel"
              callBack={() => {
                handleCloseModal();
                formik.resetForm();
              }}
            />
            {!intendStore?.loadingCreate && (
              <FillButton
                elevation
                text="Submit"
                callBack={formik.handleSubmit}
              />
            )}
            {intendStore?.loadingCreate && (
              <div>
                <CircularProgress color="secondary" />
              </div>
            )}
          </div>
        </div>
      </ModalTemplate>

      <AnimatePresence>
        {(openPopper || popperHovered) && (
          <motion.div variants={mainAnim} exit={"exit"}>
            <PopDetail
              openPopper={openPopper}
              handlePopperMouseEnter={handlePopperMouseEnter}
              handlePopperMouseLeave={handlePopperMouseLeave}
              popperHovered={popperHovered}>
              <div className="flex flex-col gap-4 px-8 py-2 w-full ">
                <CustomRow
                  td1Style="w-[200px]"
                  title="intent Id"
                  desc={viewIntendData?.intent_id ?? ""}
                />
                <CustomRow
                  td1Style="w-[200px]"
                  title="commodity"
                  desc={viewIntendData?.commodity ?? ""}
                />
                <CustomRow
                  td1Style="w-[200px]"
                  title="organization entity"
                  desc={viewIntendData?.origination_entity?.data ?? ""}
                />
                <CustomRow
                  td1Style="w-[200px]"
                  title="action"
                  desc={JSON.stringify(viewIntendData?.action ?? "")}
                />
                <CustomRow
                  td1Style="w-[200px]"
                  title="insights"
                  desc={viewIntendData?.insights ?? ""}
                />
              </div>
            </PopDetail>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="flex flex-col gap-6">
        {/* <div className="flex justify-between mx-3">
          <TextAndField space={0.5}>
            <p className="text-[12px]">Sorting Filter</p>
            <FieldSelectWithOutFormik
              fontSize="12px"
              width="200px"
              value={sortingCriteria}
              list={[
                { name: "None", id: "none" },
                { name: "Intent ID", id: "intent_id" },
                { name: "Intent", id: "intent" },
                { name: "Action", id: "action" },
                { name: "Commodity", id: "commodity" },
              ]}
              callback={(e) => {
                setSortingCriteria(e.target.value as SortingTypes);
                handleSort(e.target.value as SortingTypes);
              }}
            />
          </TextAndField>
          <TrainModelButton />
        </div> */}
        <section className={styles.filters}>
          <div className={styles.content}>
            <div className={styles.top}>
              <label htmlFor="status" className={styles.label}>
                Status
              </label>
              <input
                value={statusText}
                placeholder="status"
                id="status"
                type="text"
                onClick={() => setStatusActive(!statusActive)}
                className={styles.input}
              />
              {statusActive && (
                <div
                  className={`${styles.dropDownContainer} ${styles.dropDownContainerAlt}`}>
                  {statusList.map((itm) => {
                    return (
                      <p
                        onClick={() => {
                          setCurrentStatus(itm.value);
                          setStatusActive(false);
                          setStatusText(itm.label);
                        }}
                        key={itm.id}
                        className={styles.Status}>
                        {itm.label}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>

        <Chart chartData={data} />

        <div className="overflow-auto h-[80vh] mr-3">
          <table className="w-full ">
            <thead className="sticky top-0 z-10 w-full bg-gradient-to-r from-[#C3C3C3] to-[#EEEEEE] text-[16px] font-medium bg-primary">
              <td className="py-6 pl-6 w-[25%]">Intent ID</td>
              <td className="w-[15%]">Intent</td>
              <td className="w-[24%]">Action</td>
              <td className="w-[24%]">Commodity</td>
              <td className="w-[25%] pr-[3rem]">Status</td>
            </thead>
            <tr
              key={"sticky"}
              className="sticky w-full top-16 bg-[white] text-primary font-medium">
              <td className="py-6 pl-6">
                <OutlinedButton
                  text="New Intent"
                  style={{ fontWeight: "600", textSize: "16px" }}
                  variant="text"
                  themeColor={colors.primary}
                  callBack={handleOPenModal}
                  Icon={<BsPlus className="text-[22px]" />}
                />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            {dataToMap
              ?.filter((itm) => itm.active !== currentStatus)
              .map((val, index) => {
                return editableRowIndex !== index ? (
                  <tr
                    key={val._id}
                    className={`w-full hover:cursor-pointer hover:shadow-lg 
                        ${
                          index % 2 !== 0
                            ? "hover:opacity-[0.7]"
                            : "hover:opacity-[0.7] hover:bg-[#efefef]"
                        }  
                        ${
                          index % 2 !== 0
                            ? "bg-[white]"
                            : "bg-gradient-to-r from-[#E7E7E7] to-[#EEEEEE]"
                        } 
                        text-[#717171] text-[16px] ${
                          indexxx === index
                            ? "opacity-[0.8] shadow-lg bg-[white]"
                            : ""
                        }`}
                    onMouseEnter={(event) => {
                      let timeoutId = setTimeout(() => {
                        handleOpenPopper(event, index, val);
                      }, 1000);
                      event.currentTarget.addEventListener("mouseleave", () => {
                        clearTimeout(timeoutId);
                      });
                    }}
                    onMouseLeave={() => setOpenPopper(null)}
                    onDoubleClick={() => {
                      setEditableRowIndex(index);
                      setEditedAction(null);
                      setEditedCommodity(null);
                      setEditedIntendId(null);
                      setEditedIntend(null);
                    }}>
                    <td className="py-6 pl-6">{val.intent_id}</td>
                    <td className="">{val.intent}</td>
                    <td className="">{val.action}</td>
                    <td className="">{val.commodity}</td>
                    <td
                      className={
                        val.active === 1 ? " text-[green]" : "text-[red]"
                      }>
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <>
                            {val.active === 1 ? "active" : "inactive"}
                            {intendStore.loadingEdit &&
                              editedIntendId === val.intent_id &&
                              "..."}
                          </>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent>
                          {val.active === 1 ? (
                            <DropdownMenuItem className="text-[red]">
                              <p
                                onClick={() => {
                                  handleEditStatus({ ...val, active: 0 });
                                  setEditedIntendId(val.intent_id);
                                }}>
                                Inactive
                              </p>
                            </DropdownMenuItem>
                          ) : (
                            <DropdownMenuItem className="text-[green]">
                              <p
                                onClick={() => {
                                  handleEditStatus({ ...val, active: 1 });
                                  setEditedIntendId(val.intent_id);
                                }}>
                                Active
                              </p>
                            </DropdownMenuItem>
                          )}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={val._id}
                    className={`w-full
                         ${
                           index % 2 !== 0
                             ? "hover:opacity-[0.7]"
                             : "hover:opacity-[0.7] hover:bg-[#efefef]"
                         }  
                         ${
                           index % 2 !== 0
                             ? "bg-[white]"
                             : "bg-gradient-to-r from-[#E7E7E7] to-[#EEEEEE]"
                         } 
                         text-[#717171] text-[16px] `}
                    onDoubleClick={() => setEditableRowIndex(index)}>
                    <td className="py-6 pl-6">
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeIn", duration: 0.5 }}>
                        <CustomInputNoFormik
                          disable
                          withCancel={false}
                          value={editedIntendId ?? val.intent_id}
                          handleChange={(e) =>
                            setEditedIntendId(e.target.value)
                          }
                          // handleOnKeyUp={(e)=> {
                          //     if(e.key === 'Enter') setOpenIntend(true)
                          // }}
                        />
                      </motion.div>
                    </td>
                    <td className="py-6 pl-6">
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeIn", duration: 0.5 }}>
                        <CustomInputNoFormik
                          withCancel={false}
                          value={editedIntend ?? val.intent}
                          handleChange={(e) => setEditedIntend(e.target.value)}
                          // handleOnKeyUp={(e)=> {
                          //     if(e.key === 'Enter') setOpenIntend(true)
                          // }}
                        />
                      </motion.div>
                    </td>

                    <td className="py-6 pl-6">
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeIn", duration: 0.5 }}>
                        <CustomInputNoFormik
                          withCancel={false}
                          value={editedAction ?? val.action}
                          handleChange={(e) => setEditedAction(e.target.value)}
                          // handleOnKeyUp={(e)=> {
                          //     if(e.key === 'Enter') setOpenIntend(true)
                          // }}
                        />
                      </motion.div>
                    </td>
                    <td>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeIn", duration: 0.5 }}>
                        <CustomInputNoFormik
                          disable
                          withCancel={false}
                          value={editedIntendId ?? val.intent_id}
                          handleChange={(e) =>
                            setEditedIntendId(e.target.value)
                          }
                          // handleOnKeyUp={(e)=> {
                          //     if(e.key === 'Enter') setOpenIntend(true)
                          // }}
                        />
                      </motion.div>
                    </td>
                    <td className="py-6 pl-6">
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeIn", duration: 0.5 }}>
                        <CustomInputNoFormik
                          withCancel={false}
                          value={editedIntend ?? val.intent}
                          handleChange={(e) => setEditedIntend(e.target.value)}
                          // handleOnKeyUp={(e)=> {
                          //     if(e.key === 'Enter') setOpenIntend(true)
                          // }}
                        />
                      </motion.div>
                    </td>

                    <td className="py-6 pl-6">
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeIn", duration: 0.5 }}>
                        <CustomInputNoFormik
                          withCancel={false}
                          value={editedAction ?? val.action}
                          handleChange={(e) => setEditedAction(e.target.value)}
                          // handleOnKeyUp={(e)=> {
                          //     if(e.key === 'Enter') setOpenIntend(true)
                          // }}
                        />
                      </motion.div>
                    </td>
                    <td>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeIn", duration: 0.5 }}>
                        <div className="flex gap-2">
                          <CustomInputNoFormik
                            withCancel={false}
                            value={editedCommodity ?? val.commodity}
                            handleChange={(e) =>
                              setEditedCommodity(e.target.value)
                            }
                            // handleOnKeyUp={(e)=> {
                            //     if(e.key === 'Enter') setOpenIntend(true)
                            // }}
                          />
                          <CustomIconButton
                            loading={intendStore.loadingEdit}
                            Icon={<FiSave className="text-[16px]" />}
                            callBack={() => {
                              handleEdit(val);
                            }}
                          />
                        </div>
                      </motion.div>
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Intend;
