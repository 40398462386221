import {MdOutlineCancel} from 'react-icons/md'

type ErrorType = {
  message:string,
  handleClick:()=>void
}

const Error = ({message, handleClick}:ErrorType) => {
  return (
        <div className='flex flex-row text-[red] justify-center mt-1 p-1 rounded-md shadow-lg'>
          <p className='text-[12px] align-middle'>{message}</p>
          <MdOutlineCancel className='text-[23px] cursor-pointer' onClick={handleClick}/>
        </div>
  )
}

export default Error