import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getIntends,
  createIntend,
  editIntend,
  editIntendStatus,
  predictIntend,
} from "../network/intend";

interface IntendState {
  intend: any;
  loading: boolean;
  loadingCreate: boolean;
  loadingEdit: boolean;
  error?: string;
}

const initialState: IntendState = {
  intend: [],
  loading: false,
  loadingCreate: false,
  loadingEdit: false,
};

export const getIntendThunk = createAsyncThunk("intend/getIntends", getIntends);

export const predictIntendThunk = createAsyncThunk(
  "intend/predictIntend",
  predictIntend
);

export const createIntendThunk = createAsyncThunk(
  "intend/createIntend",
  createIntend
);
export const editIntendThunk = createAsyncThunk(
  "intend/editIntend",
  editIntend
);
export const editIntendStatusThunk = createAsyncThunk(
  "intend/editIntendStatus",
  editIntendStatus
);
export const getIntendWithoutLoadingThunk = createAsyncThunk(
  "intend/getIntends",
  getIntends
);

const IntendsSlice = createSlice({
  name: "intend",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getIntendThunk.pending, (state) => {
        state.loading = true;
      })

      .addCase(
        getIntendThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.intend = action.payload;
        }
      )

      .addCase(getIntendThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createIntendThunk.pending, (state) => {
        state.loadingCreate = true;
      })

      .addCase(createIntendThunk.fulfilled, (state) => {
        state.loadingCreate = false;
      })

      .addCase(createIntendThunk.rejected, (state, action) => {
        state.loadingCreate = false;
        state.error = action.error.message;
      })

      .addCase(editIntendThunk.pending, (state) => {
        state.loadingEdit = true;
      })

      .addCase(editIntendThunk.fulfilled, (state) => {
        state.loadingEdit = false;
      })

      .addCase(editIntendThunk.rejected, (state, action) => {
        state.loadingEdit = false;
      })

      .addCase(editIntendStatusThunk.pending, (state) => {
        state.loadingEdit = true;
      })

      .addCase(editIntendStatusThunk.fulfilled, (state) => {
        state.loadingEdit = false;
      })

      .addCase(editIntendStatusThunk.rejected, (state, action) => {
        state.loadingEdit = false;
      })

      .addCase(predictIntendThunk.pending, (state) => {
        state.loading = true;
      })

      .addCase(predictIntendThunk.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(predictIntendThunk.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default IntendsSlice.reducer;
