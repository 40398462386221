import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../Reusable/Layout";
import styles from "./test.module.css";
import loadingSpinner from "../../../../src/assets/Svg/loading-block.svg";
import keyboard from "../../../../src/assets/Svg/keyboard.svg";
import send from "../../../../src/assets/Svg/send.svg";
import { toast } from "react-toastify";
import { Storage } from "../../../network/StorageClass";
import { useSelector } from "react-redux";

interface ReceivedData {
  msg: string;
  ans: string;
  isUser: boolean;
  typing: boolean;
}

const TestClive = () => {
  const storage = new Storage();
  const user = storage.getUserDetails();
  const [text, setText] = useState<string>("");
  const [chatData, setChatData] = useState<ReceivedData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<any>([]);
  const { toggled } = useSelector((state: any) => state.cliveState);

  let endpoint = process.env.REACT_APP_DEV_CHAT_CLIVE;

  if (toggled) {
    endpoint = process.env.REACT_APP_PROD_CHAT_CLIVE;
  }

  useEffect(() => {
    if (toggled) {
      toast.info("Chat with Clive is in production mode");
    } else {
      toast.info("Chat with Clive is in dev mode");
    }
  }, [toggled]);
  const sleep = useCallback((ms: number) => new Promise((resolve) => setTimeout(resolve, ms)), []);

  const resetTextAreaSize = () => {
    const promptBox = document.getElementById("prompt_box");
    if (promptBox) {
      promptBox.style.height = "30px";
    }
  };

  const handlePredict = useCallback(async () => {
    resetTextAreaSize();
    const username = "cliengr";
    const password = "admin0000#";
    const basicAuth = "Basic " + btoa(username + ":" + password);
    if (text.trim().length <= 0) {
      toast.error("Please enter a prompt");
      return;
    }
    setText("");
    setLoading(true);
    if (endpoint) {
      try {
        setMessages((prevMessages: any) => [
          ...prevMessages,
          { prompt: text, responses: [], type: "sent" },
        ]);

        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: basicAuth,
          },
          body: JSON.stringify({
            sentence: text,
            userId: user?._id,
            content_url: "",
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to send message");
        }

        if (response.ok) {
          setLoading(false);
        }
        const stream = response.body;
        const reader = stream?.getReader();

        const readChunk = () => {
          reader
            ?.read()
            .then(({ done, value }) => {
              if (done) {
                return;
              }
              const chunk = new TextDecoder().decode(value);
              setMessages((prevMessages: any) => {
                const lastMessage = prevMessages[prevMessages.length - 1];
                return prevMessages.slice(0, -1).concat({
                  ...lastMessage,
                  responses: [...lastMessage.responses, chunk],
                });
              });

              readChunk();
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        };
        readChunk();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }, [text, endpoint, user, setLoading]);

  useEffect(() => {
    const simulateTyping = async () => {
      if (chatData.length > 0) {
        const lastMessage = chatData[chatData.length - 1];

        if (lastMessage.typing) {
          await sleep(100);
          setChatData((prevChatData) => {
            const updatedChatData = [...prevChatData];
            updatedChatData[updatedChatData.length - 1] = {
              ...updatedChatData[updatedChatData.length - 1],
              typing: false,
            };
            return updatedChatData;
          });
        }
      }
    };

    simulateTyping();
  }, [chatData, setChatData, sleep]);

  useEffect(() => {
    const scrollToBottom = () => {
      const chatContainer = document.getElementById("chatContainer");
      if (chatContainer) {
        // Smooth scrolling to the bottom
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "smooth",
        });
      }
    };

    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const autoResizePromptBox = () => {
      const promptBox = document.getElementById("prompt_box");
      promptBox?.addEventListener("keyup", () => {
        promptBox.style.height = "30px";
        const height = promptBox.scrollHeight;
        promptBox.style.height = `${height}px`;
      });
    };
    autoResizePromptBox();
    return () => {
      const promptBox = document.getElementById("prompt_box");
      promptBox?.removeEventListener("keyup", autoResizePromptBox);
    };
  }, []);

  return (
    <Layout title="Test Clive" page="test">
      <div className={styles.main}>
        <div className={styles.content}>
          <div id="chatContainer" className={styles.chatContainer}>
            {messages.map((message: any, index: number) => (
              <div key={index} className={styles.chatContent}>
                <p className={styles.prompt}>{message.prompt}</p>
                {message.responses.length > 0 && (
                  <p
                    className={styles.response}
                    dangerouslySetInnerHTML={
                      message.responses.join("").includes("\n")
                        ? {
                            __html: message.responses.join("").replace(/\n/g, "<hr  />"),
                          }
                        : { __html: message.responses.join("") }
                    }
                  />
                )}
              </div>
            ))}
            {loading && <img src={loadingSpinner} alt="loader" className={styles.loadingSpinner} />}
          </div>
          <div className={`${styles.inputContainer} ${loading && styles.disable}`}>
            <div>
              <img src={keyboard} alt="keyboard" className={styles.icon} />
            </div>
            <textarea
              id="prompt_box"
              value={text}
              onChange={(e) => setText(e.target.value)}
              disabled={loading}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (text.trim().length > 0) {
                    handlePredict();
                  } else {
                    toast.error("A prompt is required");
                  }
                }
              }}
              placeholder="Try a sentence..."
              className={styles.input}
            />
            <button onClick={handlePredict}>
              <img src={send} alt="send" className={styles.icon} />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TestClive;
