import { useEffect } from "react";
import Layout from "../../Reusable/Layout";
import styles from "./test.module.css";
import loadingSpinner from "../../../../src/assets/Svg/loading-block.svg";
import keyboard from "../../../../src/assets/Svg/keyboard.svg";
import send from "../../../../src/assets/Svg/send.svg";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import usePridiction from "./hooks/usePridiction";

interface ReceivedData {
  msg: string;
  ans: {
    time: string;
    error?: string;
    sentence: string;
    confidence: number;
    intendId?: string;
  };
}

const Prediction = () => {
  const { isLoading, setText, text, chatData, predict } = usePridiction();

  const handlePredict = () => {
    predict();
  };

  useEffect(() => {
    const scrollToBottom = () => {
      const chatContainer = document.getElementById("chatContainer");
      if (chatContainer) {
        // Smooth scrolling to the bottom
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "smooth",
        });
      }
    };

    scrollToBottom();
  }, [chatData]);

  return (
    <Layout title="Prediction" page="prediction">
      <div className={styles.layout}>
        <div id="chatContainer" className={styles.chatContainer}>
          {chatData.map((message: ReceivedData, index: number) => (
            <div key={index} className={styles.test}>
              <div className={styles.messageContainer}>
                {message.msg && <p className={styles.message}>{message.msg}</p>}
              </div>
              <div className={styles.responseContainer}>
                {isLoading && index === chatData.length - 1 ? (
                  <div className={styles.loading}>
                    <img
                      src={loadingSpinner}
                      alt="loading"
                      className={styles.img}
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <div className="border-1 border-light-gray text-[#454545] rounded-md p-3 shadow-md text-[16px]">
                      <table className="">
                        <thead className="">
                          <td className=""></td>
                          <td className=""></td>
                        </thead>

                        <tr>
                          <td>Time:</td>{" "}
                          <td className="pl-5">
                            {dayjs(message.ans.time).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr className="mt-2">
                          <td>Sentence:</td>{" "}
                          <td className="pl-5">{message.ans.sentence}</td>
                        </tr>
                        {message.ans.intendId && (
                          <tr className="mt-2">
                            <td>IntendID:</td>{" "}
                            <td className="pl-5">{message.ans.intendId}</td>
                          </tr>
                        )}
                        {message.ans.error && (
                          <tr className="mt-2">
                            <td>Error:</td>{" "}
                            <td className="pl-5">{message.ans.error}</td>
                          </tr>
                        )}

                        <tr className="mt-2">
                          <td>Confidence:</td>{" "}
                          <td className="pl-5">{message.ans.confidence}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className={styles.inputContainer}>
          <div>
            <img src={keyboard} alt="keyboard" className={styles.icon} />
          </div>
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (text.trim().length > 0) {
                  handlePredict();
                } else {
                  toast.error("A prompt is required");
                }
              }
            }}
            placeholder="Try a sentence..."
            className={styles.input}
          />
          <button onClick={handlePredict}>
            <img src={send} alt="send" className={styles.icon} />
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Prediction;
