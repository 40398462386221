import { useState, useEffect, ReactNode, useCallback } from "react";
import Layout from "../../Reusable/Layout";
import { AnimatePresence, motion } from "framer-motion";
import PopDetail from "../../Reusable/Details/PopDetail";
import IntendsDetail from "../../Reusable/Details/IntendDetail";
import { BsPlus } from "react-icons/bs";
import { OutlinedButton, CustomIconButton } from "../../Reusable/Form/Form";
import { colors } from "../../Reusable/Details/Colors";
import { FaTimes } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import Select from "react-select";
import { CustomInputNoFormik } from "../../Reusable/Form/input";
import { getSentenceThunk, editSentenceThunk } from "../../../store/sentence";
import { useDispatch, useSelector } from "react-redux";
import { handlePopperValType, CreateSentenceTypes, CreateIntendType } from "../../types/types";
import { toast } from "react-toastify";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { getIntendThunk } from "../../../store/intend";
import TrainModelButton from "../../Reusable/TrainModelButton";
import { useNavigate } from "react-router-dom";
import { useSelect } from "downshift";
import styles from "./Sentences.module.css";
import { MdDelete } from "react-icons/md";
import Confirm from "../../Reusable/Form/Confirm";
import { useCreateSentence, useDeleteSentence } from "./hooks";
import { setSentenceData } from "../../../store/sentence";

const Sentences = () => {
	const dispatch: ThunkDispatch<"auth", void, AnyAction> = useDispatch();
	const navigate = useNavigate();

	const data = useSelector((state: any) => state.sentence.sentence);

	const [popperHovered, setPopperHovered] = useState(false);
	const [openPopper, setOpenPopper] = useState<EventTarget | null>(null);
	const [indexxx, setIndex] = useState<number | null>(null);
	const [openSentence, setOpenSentence] = useState<Boolean>(false);
	const [sentence, setSentence] = useState<string>("");
	const [openIntend, setOpenIntend] = useState<Boolean>(false);
	const [intend, setIntend] = useState<string>("");
	const [allIntend, setAllIntend] = useState<any[]>([]);
	const [allCommodity, setAllCommodity] = useState<any[]>([]);
	const [rawIntend, setRawIntend] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [allSentence, setAllSentence] = useState<handlePopperValType[]>(data);
	const [dataToMap, setDataToMap] = useState<handlePopperValType[]>(data);
	const [intentText, setIntentText] = useState("");
	const [commodityText, setCommodityText] = useState("");
	const [intentActive, setIntentActive] = useState<boolean>(false);
	const [commodityActive, setCommodityActive] = useState<boolean>(false);
	const [displayData, setDisplayData] = useState<CreateIntendType | null>(null);
	const [editableRowIndex, setEditableRowIndex] = useState<number | null>(null);
	const [editedSentence, setEditedSentence] = useState<string | null>(null);
	const [editedIntend, setEditedIntend] = useState<string | null>(null);
	const [editingSentence, setEditingSentence] = useState<boolean>(false);
	const [tempAction, setTempAction] = useState<string | null>(null);


	//match the array of intent to the available sentences.
	const getFilteredData = rawIntend?.filter((itm) => {
		return dataToMap?.some((itm2) => itm.intent_id === itm2.intent_id);
	});

	const handleFilter = (itm: any, id: string) => {
		const filter = dataToMap?.filter((itm) => itm.intent_id === id);
		dispatch(setSentenceData(filter));
	};

	const resetAll = () => {
		setIntentText("");
		setCommodityText("");
		dispatch(setSentenceData(dataToMap));
	};

	const filteredSentences = getFilteredData.filter(
		(itm) => itm.action.includes(intentText) || itm.intent_id.toString().includes(intentText)
	);

	const filteredCommodities = getFilteredData?.filter((itm) =>
		itm.action.includes(commodityText)
	);

	useEffect(() => {
		dispatch(getIntendThunk()).then((val: any) => {
			if (val.payload === undefined) navigate("/");
			setRawIntend(val?.payload?.data);

			const processData = val?.payload?.data?.map((val: any) => ({
				value: val?.intent_id,
				label: val?.intent_id,
				action: val?.action,
				commodity: val?.commodity,
			}));
			setIntend(processData[0]?.label ?? "");
			setAllIntend(processData);
			setAllCommodity(processData);
		});
	}, [dispatch, navigate]);

	const getSentence = useCallback(() => {
		setIsLoading(true);
		dispatch(getSentenceThunk() as any)
			.then((val: { payload: any }) => {
				setIsLoading(false);
			})
			.catch(() => setIsLoading(false));
	}, [dispatch]);

	const {
		handleOpenDeleteConfirm,
		handleDeleteSentence,
		isLoading: loadingDeleteSentence,
		deleteConfirm,
		setDeleteConfirm,
	} = useDeleteSentence();

	const { isLoading: savingSentence, handleSaveSentence } = useCreateSentence(
		setOpenIntend,
		setOpenSentence,
		sentence,
		intend
	);

	useEffect(() => {
		getSentence();
	}, [getSentence]);

	const handleEdit = (dataa: handlePopperValType) => {
		if (editedSentence === null && editedIntend === null) {
			setEditableRowIndex(null);
		} else if (editedSentence === dataa.sentence && editedIntend === dataa.intent_id) {
			setEditableRowIndex(null);
		} else {
			setEditingSentence(true);

			const datas: CreateSentenceTypes = {
				sentence: editedSentence ?? dataa.sentence,
				sentence_ner: editedSentence ?? dataa.sentence,
				intent_id: parseInt(editedIntend ?? dataa.intent_id),
			};

			dispatch(editSentenceThunk({ datas, id: dataa._id })).then((val) => {
				setEditingSentence(false);
				toast.success(val.payload.message);

				setEditableRowIndex(null);
				getSentence();
			});
		}
	};

	const handleOpenPopper = (event: React.MouseEvent, index: number, val: CreateIntendType) => {
		setOpenPopper(event.target);
		setIndex(index);
		setDisplayData(val);
	};

	const handlePopperMouseEnter = () => {
		setPopperHovered(true);
	};

	const handlePopperMouseLeave = () => {
		setPopperHovered(false);
		setIndex(null);
	};

	const mainAnim = {
		exit: {
			opacity: 0,
			transition: { ease: "easeOut", duration: 0.3 },
		},
	};

	const findIntent = (intentId: string): CreateIntendType => {
		return rawIntend.find((intent) => intent.intent_id === intentId);
	};

	return (
		<Layout title="Sentences List" page="sentences" isLoading={isLoading}>
			<div>
				<AnimatePresence>
					{(openPopper || popperHovered) && (
						<motion.div variants={mainAnim} exit={"exit"}>
							<PopDetail
								openPopper={openPopper}
								handlePopperMouseEnter={handlePopperMouseEnter}
								handlePopperMouseLeave={handlePopperMouseLeave}
								popperHovered={popperHovered}
							>
								<IntendsDetail data={displayData} />
							</PopDetail>
						</motion.div>
					)}
				</AnimatePresence>

				{deleteConfirm && (
					<Confirm
						open={deleteConfirm}
						handleClose={() => setDeleteConfirm(false)}
						handleExecute={handleDeleteSentence}
						isLoading={loadingDeleteSentence}
					/>
				)}

				<div className="flex flex-col gap-6">
					<div className="flex justify-between mx-3 items-end">
						<div>
							<p onClick={() => resetAll()} className={styles.reset}>
								reset all
							</p>
							<div className={styles.filters}>
								<div className={styles.content}>
									<div className={styles.top}>
										<label htmlFor="intents" className={styles.label}>
											Intents
										</label>
										<input
											value={intentText}
											onChange={(e) => setIntentText(e.target.value)}
											onClick={() => {
												setIntentActive(!intentActive);
												setDataToMap(allSentence);
												setIntentText("");
											}}
											placeholder="intent_id or action"
											id="intents"
											type="text"
											className={styles.input}
										/>
									</div>
									{filteredSentences?.length > 0 && intentActive && (
										<div className={styles.dropDownContainer}>
											{filteredSentences?.map((itm) => {
												return (
													<div
														key={itm._id}
														onClick={() => {
															setIntentText(itm.action);
															setIntentActive(false);
															setCommodityActive(false);
															setCommodityText("");
															handleFilter(itm, itm.intent_id);
														}}
														className={styles.dropDown}
													>
														<p className={styles.intentId}>
															{itm.intent_id}
														</p>
														<p className={styles.action}>
															{itm.action}
														</p>
													</div>
												);
											})}
										</div>
									)}
								</div>

								<div className={styles.content}>
									<div className={styles.top}>
										<label htmlFor="commodities" className={styles.label}>
											Commodity
										</label>
										<input
											value={commodityText}
											onChange={(e) => setCommodityText(e.target.value)}
											onClick={() => {
												setCommodityActive(!commodityActive);
												setCommodityText("");
											}}
											placeholder="commodities"
											id="commodities"
											type="text"
											className={styles.input}
										/>
									</div>
									{filteredCommodities?.length > 0 && commodityActive && (
										<div className={styles.dropDownContainer}>
											{filteredCommodities?.map((itm) => {
												return (
													<div
														key={itm._id}
														onClick={() => {
															setCommodityActive(false);
															setIntentActive(false);
															handleFilter(itm, itm.intent_id);
															setCommodityText(itm.commodity);
															setIntentText("");
														}}
														className={styles.dropDown}
													>
														<p className={styles.intentId}>
															{itm.commodity}
														</p>
													</div>
												);
											})}
										</div>
									)}
								</div>
							</div>
						</div>
						<TrainModelButton />
					</div>

					<div className="overflow-auto h-[80vh] mr-3">
						<table className="w-full z-30">
							<thead className="sticky top-0 w-full bg-gradient-to-r from-[#C3C3C3] to-[#EEEEEE] text-[16px] font-medium">
								<td className="py-6 pl-6 w-[35%]">Sentence</td>
								<td className="w-[20%]">Intent ID</td>
								<td className="w-[20%]">Action</td>
								<td className="w-[20%]">Commodity</td>
								<td className="w-[5%]"></td>
							</thead>
							<tr
								key={"sentence"}
								className="sticky top-16 bg-[white] z-40 text-primary font-medium"
							>
								<td className="py-6 pl-6">
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: openSentence ? 1 : 0 }}
										exit={{ opacity: 0 }}
										transition={{ ease: "easeIn", duration: 0.5 }}
									>
										{openSentence && (
											<CustomInputNoFormik
												value={sentence}
												handleChange={(e) => {
													setSentence(e.target.value);
												}}
												cancelField={() => {
													setOpenSentence(false);
												}}
												handleOnKeyUp={(e) => {
													if (e.key === "Enter") {
														handleSaveSentence(true);
														setSentence("");
													}
												}}
											/>
										)}
									</motion.div>

									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: !openSentence ? 1 : 0 }}
										exit={{ opacity: 0 }}
										transition={{ ease: "easeIn", duration: 0.5 }}
									>
										{!openSentence && (
											<OutlinedButton
												text="New Sentence"
												style={{ fontWeight: "600", textSize: "16px" }}
												variant="text"
												themeColor={colors.primary}
												callBack={() => setOpenSentence(true)}
												Icon={<BsPlus className="text-[22px]" />}
											/>
										)}
									</motion.div>
								</td>
								<td className="flex gap-2 items-center py-6" style={{ zIndex: 0 }}>
									<div className="w-[400px]">
										<CustomDownshiftSelect
											data={allIntend}
											onSelect={(item) => {
												setIntend(item);
											}}
											callBack={() => {}}
										>
											<CustomIconButton
												Icon={<FiSave className="text-[16px]" />}
												loading={savingSentence}
												callBack={handleSaveSentence}
											/>
										</CustomDownshiftSelect>
									</div>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							{data.map((val: handlePopperValType, index: number) => {
								return editableRowIndex !== index ? (
									<tr
										key={val._id}
										className={`w-full hover:z-0 hover:cursor-pointer hover:shadow-lg 
                          ${
								index % 2 !== 0
									? "hover:opacity-[0.7]"
									: "hover:opacity-[0.7] hover:bg-[#efefef]"
							}  
                          ${
								index % 2 !== 0
									? "bg-[white]"
									: "bg-gradient-to-r from-[#E7E7E7] to-[#EEEEEE]"
							} 
                          text-[#717171] text-[16px] ${
								indexxx === index ? "opacity-[0.8] z-20 shadow-lg bg-[white]" : ""
							}`}
										onDoubleClick={() => {
											setEditableRowIndex(index);
											setEditedSentence(null);
											setEditedIntend(null);
											setOpenPopper(null);
											setTempAction(null);
										}}
									>
										<td className="py-6 pl-6">{val.sentence}</td>
										<td
											className="cursor-pointer"
											onMouseEnter={(event) => {
												let timeoutId = setTimeout(() => {
													const foundIntent = rawIntend.find(
														(intent) =>
															intent.intent_id === val.intent_id
													);
													handleOpenPopper(event, index, foundIntent);
												}, 400);
												event.currentTarget.addEventListener(
													"mouseleave",
													() => {
														clearTimeout(timeoutId);
													}
												);
											}}
											onMouseLeave={() => setOpenPopper(null)}
										>
											{val.intent_id}
										</td>
										<td>{findIntent(val.intent_id)?.action}</td>
										<td>{findIntent(val.intent_id)?.commodity}</td>
										<td>
											<MdDelete
												onClick={() => {
													handleOpenDeleteConfirm(val?._id);
												}}
												className="text-[24px] text-[red]"
											/>
										</td>
									</tr>
								) : (
									<tr key={val._id} className={`w-full`}>
										<td className="py-6 pl-6">
											<motion.div
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												exit={{ opacity: 0 }}
												transition={{ ease: "easeIn", duration: 0.5 }}
											>
												<CustomInputNoFormik
													withCancel={false}
													value={editedSentence ?? val.sentence}
													handleChange={(e) =>
														setEditedSentence(e.target.value)
													}
													// handleOnKeyUp={(e)=> {
													//     if(e.key === 'Enter') setOpenIntend(true)
													// }}
												/>
											</motion.div>
										</td>
										<td>
											<motion.div
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												exit={{ opacity: 0 }}
												transition={{ ease: "easeIn", duration: 0.5 }}
											>
												<div className="flex gap-2">
													<Select
														className="basic-single w-[300px]"
														classNamePrefix="select"
														defaultValue={{
															value: val.intent_id,
															label: val.intent_id,
														}}
														isClearable
														isSearchable
														name="color"
														options={allIntend}
														onChange={(e) => {
															setEditedIntend(e?.value as string);
															setTempAction(
																findIntent(e?.value as string)
																	?.action
															);
														}}
													/>
													<CustomIconButton
														Icon={<FiSave className="text-[16px]" />}
														loading={editingSentence}
														callBack={() => {
															handleEdit(val);
															setTempAction(null);
														}}
													/>
												</div>
											</motion.div>
										</td>
										<td>
											{tempAction === null
												? findIntent(val.intent_id)?.action
												: tempAction}
										</td>
									</tr>
								);
							})}
						</table>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Sentences;

const CustomDownshiftSelect = ({
	data,
	onSelect,
	callBack,
	children,
}: {
	data: { value: string; label: string; action: string; commodity: string }[];
	onSelect: (selectedItem: string) => void;
	callBack: () => void;
	children: ReactNode;
}) => {
	const [options, setOptions] = useState<any[]>(data.map((val) => val.value));

	const {
		isOpen,
		getToggleButtonProps,
		getMenuProps,
		getItemProps,
		highlightedIndex,
		reset,
		selectedItem,
	} = useSelect({
		items: options,
		onSelectedItemChange: ({ selectedItem }) => {
			onSelect(selectedItem);
		},
	});

	const handleReset = () => {
		reset();
		onSelect("");
	};

	const [text, setText] = useState<string>("");

	const findIntent = (intentId: string) => {
		return data.find((intent) => intent.value === intentId);
	};

	useEffect(() => {
		setText("");
		setOptions(data.map((val) => val.value));
		callBack();
	}, [isOpen]);

	const handleChangeText = (val: string) => {
		setText(val);
		if (val.length >= 2) {
			const pattern = new RegExp(`\D*${val}\D*`, "i");
			const result = data.filter(
				(val) => val.label.toString().match(pattern) || val.action.toString().match(pattern)
			);

			setOptions(result.map((val) => val.value));
		} else {
			setOptions(data.map((val) => val.value));
		}
	};

	return (
		<div className="relative text-[black]">
			<div className="flex gap-2 items-center h-full py-3">
				<button
					{...getToggleButtonProps()}
					className="text-primary text-[14px] font-semibold flex gap-2 items-end "
				>
					{!selectedItem && <BsPlus className="text-[20px]" />}
					{selectedItem || "New Intent"}
				</button>

				{selectedItem && (
					<CustomIconButton
						Icon={<FaTimes className="text-[16px]" />}
						callBack={handleReset}
					/>
				)}

				{selectedItem && children}
			</div>

			{isOpen && (
				<div
					className="absolute shadow-xl w-full rounded-lg bg-[white] flex flex-col gap-2"
					{...getMenuProps({ refKey: "ref" })}
				>
					<input
						type="text"
						value={text}
						onChange={(e) => handleChangeText(e.target.value)}
						className="ml-6 mt-2 px-2 py-1 text-[12px] border-1 border-border-color focus:outline-none rounded-[5px] w-[300px]"
						placeholder="Search"
					/>

					{options.length > 0 ? (
						<ul className="max-h-[300px] overflow-y-auto rounded-lg">
							{options.map((option, index) => (
								<li
									className="py-2 px-6 flex justify-between text-[14px]"
									{...getItemProps({ item: option, index })}
									key={`${option}${index}`}
									style={{
										backgroundColor:
											highlightedIndex === index ? "lightgray" : "white",
										fontWeight: selectedItem === option ? "bold" : "normal",
									}}
								>
									<p>{option}</p>
									<p>{findIntent(option)?.action}</p>
									<p>{findIntent(option)?.commodity}</p>
								</li>
							))}
						</ul>
					) : (
						<p className="w-full text-center text-light-text text-[14px] py-10">
							Not Found
						</p>
					)}
				</div>
			)}
		</div>
	);
};
