import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { predictSentence } from '../network/testClive';


interface testCliveState {
    predictions: any;
    loading: boolean;
    error?: string;
    endPointState?: boolean
}

const initialState: testCliveState = {
    predictions: [],
    loading: false,
    endPointState: false,
};

export const predictSentenceThunk = createAsyncThunk('predictions/predictSentence', predictSentence);


const TestCliveSlice = createSlice({
    name: 'predictions',
    initialState,

    reducers: {
        toggle: (state) => {
            state.endPointState = !state.endPointState
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(predictSentenceThunk.pending, (state) => {
                state.loading = true;
            })

            .addCase(predictSentenceThunk.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                if (action.payload?.response?.status === 200) {
                    state.predictions = [...state.predictions, action?.payload]
                }

                return state
            })

            .addCase(predictSentenceThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


    },
});

export default TestCliveSlice.reducer;
export const { toggle } = TestCliveSlice.actions
