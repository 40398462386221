import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Component/Screens/Login";
import SignUp from "./Component/Screens/SignUp";
import Sentences from "./Component/Screens/Sentences/Sentences";
import Intend from "./Component/Screens/Intend/Intend";
import AppToast from "./Component/Reusable/Toaster/AppToast";
import TestClive from "./Component/Screens/TestClive/TestClive";
import { jwtDecode } from "jwt-decode";
import { useMemo } from "react";
import ProtectedRoute from "./Component/Reusable/ProtectedRoute";
import Prediction from "./Component/Screens/Prediction";

function App() {
  const getToken = useMemo(() => {
    const token = localStorage.getItem("authtoken");
    try {
      if (token) {
        const decoded = jwtDecode(token);
        return decoded;
      } else {
        return false;
      }
    } catch (error) {
      //
    }
  }, []);

  const validateToken = useMemo(() => {
    const token = getToken as any;
    if (token) {
      return true;
    } else if (token?.exp < Date.now() / 1000) {
      return false;
    } else {
      return false;
    }
  }, [getToken]);

  return (
    <div>
      <AppToast />
      <BrowserRouter>
        <Routes>
          <Route element={<Login validateToken={validateToken} />} path="/" />
          <Route
            element={<SignUp validateToken={validateToken} />}
            path="/signup"
          />
          <Route
            element={
              <ProtectedRoute
                element={<Sentences />}
                validateToken={validateToken}
              />
            }
            path="/sentences"
          />
          <Route
            element={
              <ProtectedRoute
                element={<Intend />}
                validateToken={validateToken}
              />
            }
            path="/intent"
          />
          <Route
            element={
              <ProtectedRoute
                element={<TestClive />}
                validateToken={validateToken}
              />
            }
            path="/test"
          />

          <Route
            element={
              <ProtectedRoute
                element={<Prediction />}
                validateToken={validateToken}
              />
            }
            path="/prediction"
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
