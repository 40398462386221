import { useState } from "react"
import { deleteSentenceThunk, getSentenceThunk } from "../../../../store/sentence"
import { useDispatch } from "react-redux"
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


const useDeleteSentence = () => {

    const dispatch: ThunkDispatch<"auth", void, AnyAction> = useDispatch()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
    const [deleteId, setDeleteId] = useState<string | null>(null)
    const [isSucessdeleting, setIsSuccessDeleting] = useState(false)


    const handleOpenDeleteConfirm = (id: string) => {
    setDeleteId(id as string)
    setDeleteConfirm(true)
  }

  const handleDeleteSentence = () => {
    setIsLoading(true)
    dispatch(deleteSentenceThunk(deleteId as string)).
    then((val: any) => {
        if([200, "200"].includes(val.payload?.status) ){
            setIsSuccessDeleting(true)
            toast.success("sentence deleted")
            dispatch(getSentenceThunk())
            setDeleteConfirm(false)
        }
        setIsLoading(false)
    }).catch(err => setIsLoading(false))
  }

 

return {deleteConfirm, isSucessdeleting, setDeleteConfirm, handleDeleteSentence, handleOpenDeleteConfirm, isLoading}

}

export default useDeleteSentence