import axios from 'axios';
import { CreateSentenceTypes } from '../Component/types/types';
import { Storage } from './StorageClass';
const storage = new Storage()


interface Event {
  id: number;
  name: string;
  // add more properties here as needed
}

export const getSentence = async (): Promise<Event[]> => {
    const response = await axios.get(`${process.env.REACT_APP_DEV_STAGING_NLP}/entity/fetch-sentences/`, storage.getConfig());
    return response.data.data;
 
}

export const trainModel = async (): Promise<any> => {
  const response = await axios.get(`${process.env.REACT_APP_DEV_STAGING_NLP}/train-model/`, storage.getConfig());
  return response;

}

export const createSentence = async (all: any): Promise<any> => {
  try {
    const dataa: CreateSentenceTypes = all.data
    const response = await axios.post(`${process.env.REACT_APP_DEV_STAGING_NLP}/entity/insert-sentence/`, dataa, storage.getConfig());
    return {
      ...response.data,
      status: response.status,
    };
  } catch (err: any) {
    return { data: err.response.data, status: err.response.status };
  }


}

export const editSentence = async (all: any): Promise<any> => {
  const data: CreateSentenceTypes = all.datas
  const id: string = all.id

  const response = await axios.patch(`${process.env.REACT_APP_DEV_STAGING_NLP}/entity/update-sentence/${id}/`, data, storage.getConfig());
  return {...response.data, entry: {...data, _id: id}};

}

export const deleteSentence = async (id: string): Promise<any> => {

   try {
    const response = await axios.post(`${process.env.REACT_APP_DEV_STAGING_API}/v1/delete-sentence`, {sentenceId: id}, storage.getConfig());
    return {
      ...response.data,
      status: response.status,
    };
  } catch (err: any) {
    return { data: err.response.data, status: err.response.status };
  }


}

