import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage'


import SentenceReducer from './sentence'
import IntendsSlice from './intend'
import LoginSlice from "./login";
import TestCliveSlice from "./testClive";
import CliveState from "./cliveState/CliveState";


const persistConfig = {
  key: 'info-key',
  storage
}
const rootReducer = combineReducers({
  sentence: SentenceReducer,
  intend: IntendsSlice,
  login: LoginSlice,
  testClive: TestCliveSlice,
  cliveState: CliveState
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;