import { ReactNode } from "react";
import logo from "../../assets/logo.svg";
import {
  SentenceIcon,
  IntendIcon,
  TestCliveIcon,
  PredictionIcon,
} from "../../assets/Svg/Index";
import AvatarImg from "../../assets/avatar.png";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { colors } from "./Details/Colors";
import styles from "./layout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toggle } from "../../store/cliveState/CliveState";

interface LayoutProps {
  children: ReactNode;
  title?: string;
  page?: string;
  isLoading?: boolean;
}

const Layout = ({ children, title, page, isLoading = false }: LayoutProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toggled } = useSelector((state: any) => state.cliveState);
  return (
    <div className={styles.layout}>
      <div className={styles.sideBar}>
        <div className={styles.imgContainer}>
          <img src={logo} alt="logo" className={styles.img} />
        </div>

        <div className={styles.tabs}>
          <SentenceIcon
            active={page === "sentences"}
            callback={() => navigate("/sentences")}
          />
          <IntendIcon
            active={page === "intent"}
            callback={() => navigate("/intent")}
          />
          <TestCliveIcon
            active={page === "test"}
            callback={() => navigate("/test")}
          />

          <PredictionIcon
            active={page === "prediction"}
            callback={() => navigate("/prediction")}
          />

          <div
            onClick={() => dispatch(toggle())}
            className={
              toggled
                ? `${styles.toggleContainer} ${styles.toggleContainerAlt}`
                : `${styles.toggleContainer}`
            }>
            <div
              className={
                toggled ? `${styles.ballAlt}` : `${styles.ball}`
              }
            />
          </div>
        </div>
      </div>

      <div>
        <div className="bg-[#FEFDFD] h-[80px] w-[100%] sticky top-0 z-[1] flex justify-between items-center px-8 py-6">
          <p className="text-[20px] text-gray-58">{title}</p>
          <img
            className="h-[44px] w-[44px] rounded-full"
            src={AvatarImg}
            alt="avatar"
          />
        </div>

        <div>
          {isLoading ?
           <div className="w-[100%] flex justify-center mt-3 ">
              <CircularProgress sx={{ color: colors.secondary }} />
            </div>
            : <div className="mt-2">{children}</div> 
            
          }
        </div>
      </div>
    </div>
  );
};

export default Layout;
