import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    toggled: false,
};

const CliveState = createSlice({
    name: 'Application',
    initialState,
    reducers: {
        toggle: (state) => {
            state.toggled = !state.toggled;
        },
    }
});

export default CliveState.reducer;
export const { toggle } = CliveState.actions;
