
interface CustomRowType {
    title:string, 
    desc: string, 
    td1Style?: string
    trStyle?: string
}

const CustomRow = ({title, desc, td1Style='', trStyle=''}: CustomRowType) => {
    return (
      <tr className={`text-[#717171] text-[14px] ${trStyle}`}>
          <td className={`min-w-[150px] ${td1Style}`}>{title}:</td>
          <td>{desc}</td>
        </tr>
       
    )
  }
export default CustomRow