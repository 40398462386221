import { ReactNode } from 'react';
import { motion } from 'framer-motion';

import {Box} from '@mui/material';
import Popper from '@mui/material/Popper';

interface PopDetailProps {
  openPopper: any,
  children: ReactNode,
  popperHovered: boolean,
  handlePopperMouseEnter: ()=>void,
  handlePopperMouseLeave: ()=>void
}

const PopDetail = ({
  openPopper, 
  children, 
  popperHovered, 
  handlePopperMouseEnter, 
  handlePopperMouseLeave}: PopDetailProps) => {
  
    const open = Boolean(openPopper) || popperHovered;
    const id = open ? 'simple-popper' : undefined;
    
    
    const mainAnim ={
      hidden: {opacity: 0},
      vissible: {
        opacity: 1,
        transition: {ease: 'easeIn', duration: 0.3,},
        
      },
      exit: {
        opacity: 0,
        transition: {ease: 'easeOut', duration: 0.3}
      }
    }

  return (
    <Popper
        style={{zIndex: 999}}
        id={id} 
        open={open} 
        anchorEl={openPopper} 
        onMouseEnter={handlePopperMouseEnter}
        onMouseLeave={handlePopperMouseLeave}
        placement={'bottom-start'}>
            <motion.div
            
            variants={mainAnim}
            initial={'hidden'}
            animate={'vissible'}
            exit={'exit'}
          >
            <Box 
              sx={{
                width: '400px',
                maxHeight: '600px',
                overflowY: 'auto',
                overflowX: 'auto',
                // minHeight: '250px',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'background.paper',
                boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.08)',
                paddingTop: '20px',
                borderRadius: '10px',
                marginTop: '15px',

            }}>
              {children}

            </Box>

          </motion.div>

      </Popper>


  )
}

export default PopDetail

