import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { login } from '../network/auth'
import { LoginType } from '../Component/types/types';

interface AuthState {
userInfo: LoginType | null;
loading: boolean;
error?: string;
}

const initialState: AuthState = {
userInfo: null,
loading: false,
};

export const authLoginThunk = createAsyncThunk('login/login', login);

const LoginSlice = createSlice({
name: 'login',
initialState,

reducers: {},

extraReducers: (builder) => {
    builder
    .addCase(authLoginThunk.pending, (state) => {
    state.loading = true;
    })

    .addCase(authLoginThunk.fulfilled, (state, action: PayloadAction<any>) => {
    state.loading = false;
    state.userInfo = action.payload;
    })
    
    .addCase(authLoginThunk.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    });
},
});

export default LoginSlice.reducer;
