import axios from "axios";
import { CreateIntendType } from "../Component/types/types";

import { Storage } from "./StorageClass";
const storage = new Storage();

export const getIntends = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DEV_STAGING_NLP}/entity/fetch-intents/`,
      storage.getConfig()
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createIntend = async (all: any): Promise<any> => {
  const dataa: CreateIntendType = all.data;
  const response = await axios.post(
    `${process.env.REACT_APP_DEV_STAGING_NLP}/entity/insert-intent/`,
    dataa,
    storage.getConfig()
  );
  return { ...response.data, entry: dataa };
};

export const editIntend = async (all: any): Promise<any> => {
  const data: any = all.datas;
  const id: string = all.id;

  const response = await axios.patch(
    `${process.env.REACT_APP_DEV_STAGING_NLP}/entity/update-intent/${id}/`,
    data,
    storage.getConfig()
  );
  return {
    ...response.data,
    entry: { ...data, _id: id },
    status: response.status,
  };
};

export const editIntendStatus = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DEV_STAGING_API}/v1/set-intent-status`,
      data,
      storage.getConfig()
    );
    return {
      ...response.data,
      status: response.status,
    };
  } catch (err: any) {
    return { data: err.response.data, status: err.response.status };
  }
};

export const predictIntend = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DEV_AI_GATEWAY}/recognize-intent`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authtoken")}`,
          "Content-Type": "application/json",
        },
        auth: {
          username: "cliengr",
          password: "admin0000#",
        },
      }
    );
    return {
      ...response.data,
      status: response.status,
    };
  } catch (err: any) {
    console.log(err?.response);
    return { data: err?.response?.data, status: err?.response?.status };
  }
};
