import React from "react";
import { Navigate } from "react-router-dom";

interface Props {
  element: React.ReactNode;
  validateToken: boolean;
}

const ProtectedRoute: React.FC<Props> = ({
  element,
  validateToken,
  ...props
}) => {  
  if (!validateToken) {
    return <Navigate to="/" />;
  }
  return element;
};

export default ProtectedRoute;
