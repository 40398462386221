import { CreateIntendType } from "../../types/types"
import CustomRow from "./CustomRow"


const IntendsDetail = ({data}: {data: CreateIntendType | null}) => {
  return (
    <div className="flex flex-col gap-4 px-8 py-2 w-full" >
        <CustomRow title="intent Id" desc={data?.intent_id ?? ''} />
        <CustomRow title="commodity" desc={data?.commodity ?? ''} />
        <CustomRow title="organization entity" desc={data?.origination_entity.data ?? ''} />
        <CustomRow title="action" desc={ JSON.stringify(data?.action ?? '') } />
        <CustomRow title="insights" desc={ data?.insights ?? '' } />

        <div>
          <p className="text-[18px] text-[#3d7a60] cursor-pointer mt-4">Go To Intent #{data?.intent_id}</p>
        </div>
    </div>
  )
}

export default IntendsDetail



