import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSentence, createSentence, editSentence, deleteSentence } from '../network/sentence';

interface Chapter {
id: number;
name: string;
// add more properties here as needed
}

interface SentenceState {
sentence: any;
loading: boolean;
error?: string;
}

const initialState: SentenceState = {
sentence: [],
loading: false,
};

export const getSentenceThunk = createAsyncThunk('sentence/getSentence', getSentence);
export const createSentenceThunk = createAsyncThunk('sentence/createSentence', createSentence);
export const editSentenceThunk = createAsyncThunk('sentence/editSentence', editSentence);
export const deleteSentenceThunk = createAsyncThunk('sentence/deleteSentence', deleteSentence);


const SentenceSlice = createSlice({
name: 'sentence',
initialState,

 reducers: {
        setSentenceData: (state, action) => {
            state.sentence = action.payload
        }
    },
extraReducers: (builder) => {
    builder
    .addCase(getSentenceThunk.pending, (state) => {
    state.loading = true;
    })

    .addCase(getSentenceThunk.fulfilled, (state, action: PayloadAction<Chapter[]>) => {
    state.loading = false;
    state.sentence = action.payload;
    })
    
    .addCase(getSentenceThunk.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    })

    .addCase(createSentenceThunk.pending, (state) => {
    state.loading = true;
    })

    .addCase(createSentenceThunk.fulfilled, (state) => {
    state.loading = false;
    })
    
    .addCase(createSentenceThunk.rejected, (state, action) => {
    state.loading = false;
    })

    .addCase(editSentenceThunk.pending, (state) => {
    state.loading = true;
    })

    .addCase(editSentenceThunk.fulfilled, (state) => {
    state.loading = false;
   
    })
    
    .addCase(editSentenceThunk.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    })

    .addCase(deleteSentenceThunk.pending, (state) => {
    state.loading = true;
    })

    .addCase(deleteSentenceThunk.fulfilled, (state) => {
    state.loading = false;
   
    })
    
    .addCase(deleteSentenceThunk.rejected, (state) => {
    state.loading = false;
    });
},
});

export default SentenceSlice.reducer;
export const { setSentenceData } = SentenceSlice.actions
