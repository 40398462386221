import { useState, useEffect } from "react";
import { trainModel } from "../../network/sentence";
import { toast } from "react-toastify";
import { FillButton } from "./Form/Form";
import { getTraningModelStatus } from "../../network/auth";//

// Import Timeout type from node module

const TrainModelButton = () => {

  const [trainingModel, setTrainingModel] = useState<boolean>(false);

  useEffect(() => {
    let intervalId: any;

    const getStatus = () => {
      if (!trainingModel) {
        // console.log(trainingModel, "am false am running");

        getTraningModelStatus()
          .then((val) => {
            // console.log(val?.data[0]?.status);
            setTrainingModel(val?.data[0]?.status === "Active" ? false : true);
          })
          .catch((err) => console.log(err));
      } else {
        // console.log(trainingModel, "am true not running");
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(getStatus, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [trainingModel]);

  const handleTrainModel = () => {
    setTrainingModel(true);
    trainModel()
      .then(() => {
        setTrainingModel(false);
        toast.success("Model trained successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        setTrainingModel(false);
      });
  };

  return (
    <div>
      <FillButton
        elevation
        text={!trainingModel ? "Train Model" : "Training in progress"}
        disable={trainingModel}
        width="200px"
        height="50px"
        callBack={handleTrainModel}
      />
    </div>
  );
};

export default TrainModelButton;
