import axios from "axios";

import { Storage } from "./StorageClass";
const storage = new Storage();

export const predictSentence = async (data: {
  sentence: string;
  userId: string;
  content: string;
}): Promise<any> => {
  try {
    const response = await axios.post(
      `http://ai.gateway.cliveai.com/api/v1/chat-clive`,
      { sentence: data.sentence, userId: data.userId, content: data.content },
      storage.getConfig()
    );
    console.log(response);
    return response.data;
  } catch (error) {
    return error;
  }
};

// const response = await axios.post(`${process.env.REACT_APP_DEV_STAGING_API}/${data.id}`, {sentence: data.msg},  storage.getConfig());
