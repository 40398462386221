import axios from 'axios';
import { LoginType } from '../Component/types/types';

export const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };


export const login = async (data:LoginType): Promise<any> => {
    try {
      
      const response = await axios.post(`${process.env.REACT_APP_DEV_STAGING_API}/v1/users/signin`, data, config);
      return response.data;
    } catch (error) {
      return error
    }
 
}

export const getTraningModelStatus = async (): Promise<any> => {
  try {
    
    const response = await axios.get(`${process.env.REACT_APP_DEV_STAGING_NLP}/entity/get-training-model-status/`, config);
    return response.data;
  } catch (error) {
    return error
  }

}