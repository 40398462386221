import ModalTemplate from '../Modal';
import { IoClose } from "react-icons/io5";
import { useState } from 'react';
import { CircularProgress } from "@mui/material";


interface ConfirmModalType {
  open: boolean;
  isLoading?: boolean;
  handleClose: () => void;
  handleExecute: () => void;
}

const Confirm = ({open, handleClose, handleExecute, isLoading=false}: ConfirmModalType) => {

    const [text, setText] = useState("")
    return (
        <ModalTemplate open={open} handleClose={()=> !isLoading && handleClose()}>
            <div className='flex justify-center items-center mt-10'>
                <div className='flex flex-col gap-5 px-3 py-3 rounded-md bg-[white]'>
                    <IoClose className=' self-end cursor-pointer' onClick={()=> !isLoading && handleClose()}/>
                    <div className='flex flex-col gap-4'>
                        <p className='text-[18px]'>To continue type <span className='text-[red] italic'>delete</span> in the input below</p>
                        <input type="text" className='px-2 py-1 focus:outline-none border-1 rounded-md' value={text} onChange={(e)=> setText(e.target.value)}/>

                        {!isLoading ? 
                            <button disabled={text !== 'delete'} className=' disabled:opacity-50' onClick={handleExecute}>continue</button>: 
                            <CircularProgress className=' self-center'/>
                        }
                    </div>
                </div>
            </div>
        </ModalTemplate>
    );
}

export default Confirm;