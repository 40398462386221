import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tailwindcss/tailwind.css';
import './fonts.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";

const theme = createTheme({
  palette: {
    primary: {
      main: '#DEFFF1',
    },
    secondary: {
      main: '#00D100',
      dark: '#00A300'
    },
    error: {
      main: '#FF0000',
    },
   
  },
  typography: {
    // fontFamily: 'TT Commons, sans-serif '
  },
 
});


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If youu want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or  send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
